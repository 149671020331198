import React, { useEffect } from 'react';
import axios from 'axios';
import { useProductContext } from '../ProductContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProductBox from './ProductBox';
import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import BackButton from './BackButton';
import { v4 as uuidv4 } from 'uuid';
import './SkincarePage';

const MostDiscountedPage = () => {
  const { t } = useTranslation();
  const { products, setProducts, page, setPage, hasMore, setHasMore, loading, setLoading } = useProductContext();

  const fetchMostDiscountedItems = async (currentPage) => {
    if (loading) return; // Prevent multiple concurrent fetches
  
    try {
      setLoading(true); // Set loading to true
      const endpoint = `${process.env.REACT_APP_API_BASE_URL}/api/main/most-discounted`;
      const response = await axios.get(endpoint, { params: { page: currentPage } });
  
      const newProducts = response.data.products || [];
      setProducts((prevProducts) =>
        currentPage === 1 ? newProducts : [...prevProducts, ...newProducts]
      );
      setHasMore(currentPage < response.data.totalPages);
    } catch (error) {
      console.error('Error fetching discounted products:', error);
    } finally {
      setLoading(false); // Set loading to false after fetch completes
    }
  };

  const loadMoreProducts = () => {
    if (hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };  

  useEffect(() => {
    fetchMostDiscountedItems(1);
  }, []);
  
  useEffect(() => {
    fetchMostDiscountedItems(page);
  }, [page]);
  

  return (
    <div className="container">
      <Helmet>
        <title>{t('mostDiscountedItemsPage.title')} - GlamAlertz</title>
        <meta
          name="description"
          content={t('mostDiscountedItemsPage.metaDescription')}
        />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <Header />
      <BackButton />

      <div className="skincare-products">
        <h1>{t('mostDiscountedItemsPage.title')}</h1>
        <p>{t('mostDiscountedItemsPage.description')}</p>

        <InfiniteScroll
          dataLength={products.length}
          next={loadMoreProducts}
          hasMore={hasMore}
          loader={<p>{t('general.loading')}</p>}
          endMessage={<p>{t('general.noMoreProducts')}</p>}
        >
          <div className="product-list">
            {products.map((product) => (
                 <div key={uuidv4()} className="product">
              <ProductBox key={product._id} product={product} />
              </div>
            ))}
          </div>
        </InfiniteScroll>
      </div>

      <Footer />
    </div>
  );
};

export default MostDiscountedPage;